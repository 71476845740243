// Font Family
$roboto: 'Roboto', sans-serif;


// Colors ---------------
$white: #fff;

$black: #222;

$grey:#eef1f6;

$theme-color: #2b70fa;
$theme-color-2: #ff3e66;
$theme-color-3: #ff6b58;
$theme-color-4: #801f82;
$theme-color-5: #f84a6e;
$theme-color-6: #9b2cfa;
$theme-color-7: #db0f30;

$text-color: #505056;

$hr-border-color:#eceff8;


// Heading Color
$heading-color: #0e1133;

// Soft color
$black-soft:#63636a;


// Responsive Variables
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';


