


/*===========================
    14.APPIE DOWNLOAD css 
===========================*/




.appie-download-area{
	background-image: url(../images/download-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	position: relative;
	z-index: 10;
	@media #{$md} {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	&::before{
		position: absolute;
		content: '';
		right: 170px;
		top: 50%;
		transform: translateY(-50%);
		height: 100%;
		width: 860px;
		background-image: url(../images/download-thumb.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
		@media #{$lg} {
			right: 0;
			width: 550px;
		}
		@media #{$md} {
			display: none;
		}
		@media #{$xs} {
			display: none;
		}

	}
	& .download-shape-1{
		position: absolute;
		left: 95px;
		top: 95px;
		z-index: -1;
		animation: linear 20s animationFramesFour infinite;
	}
	& .download-shape-2{
		position: absolute;
		left: 150px;
		bottom: 100px;
		z-index: -1;
		animation: linear 20s animationFramesTwo infinite;
	}
	& .download-shape-3{
		position: absolute;
		top: 140px;
		right: 250px;
		z-index: -1;
		animation: linear 20s animationFramesFive infinite;
	}
}

.appie-download-content{
	& > span{
		font-size: 14px;
		font-weight: 500;
		color: $theme-color-2;
	}
	& .title{
		font-size: 44px;
		line-height: 54px;
		margin-top: 9px;
		margin-bottom: 13px;
		@media #{$lg} {
			font-size: 36px;
			line-height: 46px;
		}
		@media #{$xs} {
			font-size: 30px;
			line-height: 40px;
		}
	}
	& ul{
		margin: 30px 0 0;
		padding: 0;
		list-style-type: none;
		& li{
			display: inline-block;
			& a{
				background: $heading-color;
				color: $white;
				padding-left: 22px;
				padding-right: 40px;
				padding-top: 14px;
				padding-bottom: 14px;
				display: flex;
				align-items: center;
				border-radius: 16px;
				margin-right: 8px;
				border: 2px solid $heading-color;
				@media #{$lg} {
					padding-right: 20px;
				}
				@media #{$xs} {
					margin-bottom: 20px;
				}
				@media #{$sm } {
					margin-bottom: 0px;
				}
				& i{
					font-size: 36px;
					padding-right: 11px;
				}
				& span{
					font-size: 14px;
					font-weight: 500;
					& span{
						font-size: 24px;
						display: block;
						line-height: 18px;
					}
				}
				&:hover{
					background: $white;
					color: $heading-color;
				}
				&.item-2{
					background: $white;
					color: $heading-color;
					&:hover{
						background: $heading-color;
						color: $white;
					}
				}
			}
		}
	}
}




.appie-sponser-area{
	position: relative;
	z-index: 10;
	& .sponser-shape{
		position: absolute;
		left: 50%;
		top: 60%;
		transform: translate(-50%, -50%);
		z-index: -1;
	}
}


.appie-sponser-box{
	@media #{$xs} {
		flex-wrap: wrap; 
	}
	& .sponser-item{
		background: $white;
		height: 100px;
		width: 100px;
		text-align: center;
		line-height: 100px;
		border-radius: 6px;
		box-shadow: 0px 40px 60px 0px rgba(14, 17, 51, 0.14);
		margin: 30px 16px 0;
	}
	&.item-2{
		& .sponser-item{
			margin: 30px 28px 0;
			@media #{$xs} {
				margin: 30px 16px 0;
			}
		}
	}
}






.appie-download-3-box{
	border-radius: 20px;
	background: #ebf2fc;
	overflow: hidden;
	@media #{$md} {
		margin:30px 0 0;
	}
	@media #{$xs} {
		margin:30px 0 0;
	}
	& .content{
		padding: 50px;
		@media #{$xs} {
			padding: 20px;
		}
		@media #{$sm} {
			padding: 50px;
		}
		& .title{
			font-size: 34px;
			font-weight: 700;
			margin-bottom: 8px;
		}
		& a{
			&.main-btn{
				background: $white;
				color: $heading-color;
				border-color: #fff;
				margin-top: 33px;
				&:hover{
					background: $theme-color-3;
					border-color: $theme-color-3;
					color: $white;
				}
				& i{
					padding-right: 6px;
				}
				&.main-btn-2{
					background: $theme-color-3;
					border-color: $theme-color-3;
					color: $white;
					&:hover{
						background: $white;
						color: $heading-color;
						border-color: $white;
					}
				}
			}
		}
	}
	&.appie-download-6-box{
		& a{
			&.main-btn{
				&:hover{
					background: $theme-color-6;
					border-color: $theme-color-6;
				}
				&.main-btn-2{
					background: $theme-color-6;
					border-color: $theme-color-6;
				}
			}
		}
	}
	&.appie-download-5-box{
		& a{
			&.main-btn{
				&:hover{
					background: $theme-color-4;
					border-color: $theme-color-4;
				}
				&.main-btn-2{
					background: $theme-color-4;
					border-color: $theme-color-4;
				}
			}
		}
	}
}
