

/*===========================
    1. COMMON css 
===========================*/


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

 body {
	font-family: $roboto;
	font-weight: normal;
	font-style: normal;
    color: $text-color;
    overflow-x: hidden;
}


*{
    margin: 0;
    padding: 0;
    @include box-sizing (border-box);
    scroll-behavior: smooth;
}

img {
	max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover{
	text-decoration: none;
}

i,
span,
a{
    display: inline-block;
    transition: all linear 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $roboto;
	font-weight: 900;
	color: $heading-color;
	margin: 0px;
}

h1 {
	font-size: 48px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
ul,ol {
	margin: 0px;
	padding: 0px;
    list-style-type: none;
}

p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: $text-color;
	margin: 0px;
}

.bg_cover{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.slick-slide {
	outline: 0;
}



// others common css here



/*===== All Button Style =====*/

.main-btn {
	display: inline-block;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	@include user-select(none);
	border: 1px solid $theme-color;
	padding: 0 30px;
	font-size: 15px;
	line-height: 45px;
	border-radius: 6px;
    color: $white;
    cursor: pointer;
    z-index: 5;
    @include transition(0.4s);
    background-color: $theme-color;
    
    &:hover{
        background-color: $white;
        color: $theme-color;
        border-color: $theme-color;
    }
    
    &.main-btn-2{
        background-color: $white;
        color: $theme-color;
        border-color: $theme-color;
        &:hover{
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
        }
    }
}










// section title

.appie-section-title{
	padding-bottom: 25px;
	& .appie-title{
		font-size: 44px;
		line-height: 54px;
		@media #{$lg} {
			font-size: 36px;
			line-height: 46px;
		}
		@media #{$md} {
			font-size: 36px;
			line-height: 46px;
		}
		@media #{$xs} {
			font-size: 26px;
			line-height: 36px;
		}
		@media #{$sm} {
			font-size: 36px;
			line-height: 46px;
		}
	}
	& p{
		font-size: 18px;
		line-height: 28px;
		margin-top: 11px;
		@media #{$lg} {
			font-size: 15px;
		}
		@media #{$md} {
			font-size: 15px;
		}
		@media #{$xs} {
			font-size: 15px;
		}
	}
	& .main-btn{
		border-radius: 30px;
		border: 2px solid #e7eaef;
		background: $white;
		color: $heading-color;
		&:hover{
			background: $theme-color-2;
			color: $white;
			border-color: $theme-color-2;
		}
	}
	&.appie-section-title-2{
		& .appie-title{
			color: $white;
		}
		& p{
			color: $white;
		}
	}
}



.back-to-top{
	position: fixed;
	right: 30px;
	bottom: 30px;
	z-index: 999;
	display: none;
	& a{
		height: 40px;
		width: 40px;
		text-align: center;
		line-height: 40px;
		background: $theme-color;
		border-radius: 6px;
		color: $white;
	}
	&.back-to-top-2{
		& a{
			background: $theme-color-2;
		}
	}
	&.back-to-top-3{
		& a{
			background: $theme-color-3;
		}
	}
	&.back-to-top-6{
		& a{
			background: $theme-color-6;
		}
	}
	&.back-to-top-5{
		& a{
			background: $theme-color-4;
		}
	}
    &.back-to-top-8{
        & a{
            background: $theme-color-7;
        }
    }
}











.off_canvars_overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9998;
	opacity: 0;
	visibility: hidden;
	cursor: crosshair;
	background: #232323;
	top: 0;
	transition: all linear 0.3s;
	&.active{
		opacity: .5;
		visibility: visible;
	}
}


.offcanvas_menu {
 	display: none;
 	@media #{$md} {
 		display: block;
 	}
 	@media #{$xs} {
 		display: block;
 	}

}


.offcanvas_menu_wrapper {
	width: 290px;
	position: fixed;
	background: #fff;
	z-index: 9999;
	top: 0;
	height: 100%;
	transition: .5s;
	left: 0;
	margin-left: -300px;
	padding: 50px 15px 30px;
	overflow-y: visible;
	&.active{
		margin-left: 0;
	}
	& .slinky-theme-default{
		background: inherit;
		min-height: 300px;
		overflow-y: auto;

	}
	& .header-btn{
		margin-bottom: 30px;
		& a{
			color: $black;
			&:hover{
				color: $black;
			}
		}
	}
}



.offcanvas_main_menu {
	& li{
		position: relative;
		&:last-child{
			margin: 0;
		}
		& span{
			&.menu-expand{
				position: absolute;
				right: 0;

			}
		}
		& a{
			font-size: 14px;
			font-weight: 500;
			text-transform: capitalize;
			display: block;
			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid #ededed;
			color: $black;
			@include transition(0.3s);
			&:hover{
				color: $theme-color;
			}

		}
		& ul{
			&.sub-menu{
				padding-left: 20px;
			}
		}
	}
}


.offcanvas_footer {
	padding-bottom: 50px;
	text-align: center;
	& span{
		& a{
			font-size: 14px;
			color: $black;
			@include transition(0.3s);
			&:hover{
				color: $theme-color;
			}
		}
	}
}


.offcanvas_menu_wrapper.active .canvas_close{
	opacity: 1;
	visibility: visible;
}


.canvas_close {
	position: absolute;
	top: 10px;
	right: -50px;
	opacity: 0;
	visibility: hidden;
	transition: all linear 0.3s;
	& a{
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 500;
		width: 40px;
		height: 40px;
		display: block;
		text-align: center;
		line-height: 40px;
		border: 1px solid $theme-color;
		border-radius: 10px;
		background: $theme-color;
		color: $white;
		@include transition(0.3s);
		&:hover{
			background: $theme-color;
			border-color: $theme-color;
			color: #fff;

		}
	}
}

.canvas_open {
	& a{
		font-size: 26px;
		width: 50px;
		height: 42px;
		display: block;
		line-height: 39px;
		text-align: center;
		border: 1px solid #232323;
		color: $black;
		@include transition(0.3s);
		&:hover{
			color: $theme-color;
			border-color: $theme-color;

		}
	}
}

.offcanvas-social{
	margin-bottom: 40px;
	margin-top: 40px;
	& ul{
		& li{
			display: inline-block;
			& a{
				height: 40px;
				width: 40px;
				text-align: center;
				line-height: 38px;
				color: $theme-color;
				border: 1px solid #eaeaea;
				border-radius: 50%;
				margin: 0 5px;
				@include transition(0.3s);
				&:hover{
					background: $theme-color;
					border-color: $theme-color;
					color: $white;
				}

			}
		}
	}
}




.toggle-btn{
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 20px;
	color: $heading-color;
}



// preloader

.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/icon/preloader.svg);
}


.preloader-close {
    position: fixed;
    z-index: 999999;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    right: 40px;
    bottom: 40px;
    font-weight: 600;
    line-height: 60px;
    background: $theme-color;
    padding: 0 15px;
    border-radius: 5px;
}

.loader-wrap {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
}

.loader-wrap .layer-one {
    position: absolute;
    left: 0%;
    top: 0;
    width: 33.3333%;
    height: 100%;
    overflow: hidden;
}

.loader-wrap .layer-two {
    position: absolute;
    left: 33.3333%;
    top: 0;
    width: 33.3333%;
    height: 100%;
    overflow: hidden;
}

.loader-wrap .layer-three {
    position: absolute;
    left: 66.6666%;
    top: 0;
    width: 33.3333%;
    height: 100%;
    overflow: hidden;
}

.loader-wrap .layer .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #F4F9FF;
}









/*===========================
    SEARCH css 
===========================*/


.search-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999999;
    @include transform(translateY(-100%));
    @include transition(0.6s);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);

    & .search-header {
        & .search-title {
            font-size: 24px;
            font-weight: 500;
            color: $white;
        }

        & .search-close {
            & button {
                background: none;
                border: 0;
                font-size: 24px;
                font-weight: 500;
                color: $white;
                padding-right: 35px;
                position: relative;

                & span {
                    width: 21px;
                    height: 2px;
                    background-color: $white;
                    display: block;
                    position: absolute;
                    right: 0;

                    &:nth-child(1) {
                        top: 13px;
                        @include transform(rotate(45deg));
                    }

                    &:nth-child(2) {
                        @include transform(rotate(135deg));
                        top: 13px;
                    }
                }
            }
        }
    }

    & .search-body {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        @include transform(translateY(-50%));

        & .search-form {
            position: relative;

            & input {
                width: 100%;
                border: 0;
                height: 80px;
                padding-left: 30px;
                border: 2px solid #fff;
                border-radius: 50px;
                font-size: 20px;
                color: #707070;

                &::placeholder {
                    color: #707070;
                    opacity: 1;
                }
            }

            & button {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                height: 60px;
                font-size: 36px;
                color: #707070;
                background: none;
                border: 0;
            }
        }
    }

    & .search-footer {
        padding-bottom: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        & .search-footer-content {
            & h4 {
                color: #707070;
                font-size: 24px;
            }

            & p {
                color: $black;
                font-size: 16px;
            }
        }
    }
}

.search-box.open {
    @include transform(translateY(0));
}




/*===========================
    SHOPPING CART css 
===========================*/

.amm-shopping-cart-wrapper {
    & .overlay {
        &::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0;
            z-index: 99999;
            visibility: visible;
            @include transition(0.2s);
            visibility: hidden;
        }

        &.open {
            &::before {
                opacity: 0.5;
                visibility: visible;
            }
        }
    }
}

.amm-shopping-cart-canvas {
    position: fixed;
    width: 400px;
    height: 100%;
    background-color: $white;
    z-index: 99999999;
    top: 0;
    right: -400px;
    padding: 40px 40px;
    @include transition(0.4s);

    @media #{$xs} {
        width: 320px;
    }

    &.open {
        right: 0;
    }

    & .amm-shopping_cart {
        position: relative;
        height: 100%;
        width: 100%;






    }
    &.amm-shopping-cart-leftbar{
        right: auto;
        left: -400px;
        &.open{
            left: 0;
        }
    }
}

.amm-shopping_cart-list-items {
    overflow-y: auto;
    width: 100%;
    height: 50%;

    & ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        & li {
            padding: 20px 0;
            border-bottom: 1px solid #e5e5e5;

            &:last-child {
                border-bottom: 0;
            }


        }
    }
}

.amm-single-shopping-cart {
    display: flex;
    & .cart-content {
        position: relative;
        padding-right: 30px;

        & h6 {
            & a {
                font-size: 16px;
                font-weight: 500;
                color: $black;
            }
        }

        & .quality {
            font-size: 14px;
            color: $text-color;
            display: block;
        }

        & .price {
            font-size: 14px;
            color: $theme-color;
        }

        & .price-discount {
            font-size: 14px;
            color: $theme-color;
        }

        & .price-close {
            font-size: 12px;
            color: $text-color;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                top: 50%;
                @include transform(translateY(-50%));
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $text-color;
            }
        }

        & .remove {
            font-size: 14px;
            color: #ccc;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.amm-shopping_cart-top-bar {
    & h6 {
        font-size: 24px;
        font-weight: 600;
        color: $black;
    }

    & button {
        width: 30px;
        height: 30px;
        padding: 0;
        border: 0;
        font-size: 30px;
        background: none;
    }
}


.amm-shopping_cart-btn {
    border-top: 1px solid #ccc;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: $white;
    z-index: 99;

    & .tota {
        & h5 {
            font-size: 18px;
            font-weight: 400;
            color: $black;
        }

        & p {
            font-size: 18px;
            color: $theme-color;
        }
    }

    & .cart-btn {
        & a {
            margin: 5px 0;
            width: 100%;

        }
    }
}




