


/*===========================
    11.APPIE ABOUT css 
===========================*/








.appie-about-area{


}



.appie-about-box{
	background: #f0f1f7;
	padding: 70px;
	overflow: hidden;
	position: relative;
	@media #{$xs} {
		padding: 30px;
		text-align: center;
	}
	& .about-thumb{
		position: absolute;
		left: 0;
		top: -65px;
		@media #{$md} {
			position: static;
		}
		@media #{$xs} {
			display: none;
		}
	}
}


.appie-about-content{
	padding-right: 50px;
	@media #{$xs} {
		padding-right: 0;
	}
	& span{
		font-size: 14px;
		font-weight: 500;
		color: $theme-color-2;
	}
	& .title{
		font-size: 44px;
		line-height: 54px;
		margin-top: 8px;
		margin-bottom: 12px;
		@media #{$lg} {
			font-size: 32px;
			line-height: 42px;
		}
		@media #{$xs} {
			font-size: 30px;
			line-height: 40px;
		}
	}
}


.appie-about-service{
	position: relative;
	padding-left: 40px;
	@media #{$xs} {
		text-align: left;
	}
	& .icon{
		position: absolute;
		left: 0;
		top: 5px;
		& i{
			height: 26px;
			width: 26px;
			text-align: center;
			line-height: 26px;
			border-radius: 50%;
			color: $white;
			background: #31c369;
		}
	}
	& .title{
		font-size: 20px;
		font-weight: 700;
		@media #{$lg} {
			font-size: 16px;
		}
	}
	& p{
		font-size: 15px;
		margin-top: 8px;
		@media #{$lg} {
			font-size: 14px;
		}
	}
}




.appie-about-3-area{
	& .appie-traffic-title{
		padding-right: 100px;
		@media #{$xs} {
			text-align: center;
			padding-right: 0;
		}
		@media #{$sm} {
			text-align: left;
			padding-right: 50px;
		}
		& .title{
			margin-bottom: 15px;
		}
	}
	& .appie-traffic-service{
		padding-left: 0;
		padding-right: 50px;
		@media #{$lg} {
			padding-right: 0;
		}
		@media #{$xs} {
			text-align: center;
			padding-right: 30px;
			padding-left: 30px;
		}
		@media #{$sm} {
			text-align: left;
			padding-right: 50px;
			padding-left: 0;
		}
		& .icon{
			position: static;
			background-image: -moz-linear-gradient( 120deg, rgb(43,112,250) 0%, rgb(132,188,250) 100%);
			background-image: -webkit-linear-gradient( 120deg, rgb(43,112,250) 0%, rgb(132,188,250) 100%);
			background-image: -ms-linear-gradient( 120deg, rgb(43,112,250) 0%, rgb(132,188,250) 100%);
			display: inline-block;
			height: 50px;
			width: 50px;
			text-align: center;
			border-radius: 50%;
			line-height: 50px;
			box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
			margin-bottom: 23px;
		}
		&.item-2{
			& .icon{
				background-image: -moz-linear-gradient( 120deg, rgb(49,195,105) 0%, rgb(160,234,194) 100%);
				background-image: -webkit-linear-gradient( 120deg, rgb(49,195,105) 0%, rgb(160,234,194) 100%);
				background-image: -ms-linear-gradient( 120deg, rgb(49,195,105) 0%, rgb(160,234,194) 100%);
			}
		}
		&.item-3{
			& .icon{
				background-image: -moz-linear-gradient( 120deg, rgb(155,44,250) 0%, rgb(216,170,255) 100%);
				background-image: -webkit-linear-gradient( 120deg, rgb(155,44,250) 0%, rgb(216,170,255) 100%);
				background-image: -ms-linear-gradient( 120deg, rgb(155,44,250) 0%, rgb(216,170,255) 100%);
			}
		}
		&.item-4{
			& .icon{
				background-image: -moz-linear-gradient( 120deg, rgb(248,69,45) 0%, rgb(247,184,175) 100%);
				background-image: -webkit-linear-gradient( 120deg, rgb(248,69,45) 0%, rgb(247,184,175) 100%);
				background-image: -ms-linear-gradient( 120deg, rgb(248,69,45) 0%, rgb(247,184,175) 100%);
			}
		}
	}
}



.appie-about-thumb-3{
	@media #{$md} {
		margin-bottom: 40px;
	}
	@media #{$xs} {
		margin-bottom: 40px;
	}
	& img{
		width: 100%;
	}
}





.appie-about-top-title-area{
	padding-top: 227px;
}

.appie-about-top-title{
	padding-right: 65px;
	@media #{$lg} {
		padding-right: 0;
	}
	@media #{$xs} {
		padding-right: 0;
	}
	& .title{
		font-size: 60px;
		position: relative;
		z-index: 10;
		@media #{$lg} {
			font-size: 52px;
		}
		@media #{$md} {
			font-size: 44px;
		}
		@media #{$xs} {
			font-size: 32px;
		}
		@media #{$sm} {
			font-size: 40px;
		}
		&::before{
			position: absolute;
			content: '';
			left: 0;
			bottom: 8px;
			width: 100%;
			height: 20px;
			background: #ff6e85;
			z-index: -1;
		}

	}
	& p{
		font-size: 20px;
		color: $heading-color;
		padding-top: 18px;
		@media #{$lg} {
			font-size: 16px;
		}
		@media #{$md} {
			font-size: 16px;
		}
		@media #{$xs} {
			font-size: 16px;
		}
	}
}






.appie-about-page-area{
	padding-top: 462px;
	padding-bottom: 100px;
	background-image: url(../images/about-page-bg.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-top: 345px;
	@media #{$xs} {
		margin-top: 80px;
		padding-top: 80px;
	}
}



.appie-about-page-content{
	position: relative;
	& .title{
		font-size: 36px;
		font-weight: 700;
		color: $white;
	}
	& p{
		color: $white;
		padding-top: 15px;
		opacity: 0.7;
	}
	& a{
		color: $white;
		border: 2px solid $white;
		border-radius: 10px;
		line-height: 40px;
		padding: 0 30px;
		font-size: 14px;
		font-weight: 500;
		margin-top: 43px;
		&:hover{
			background: $white;
			color: $theme-color;
		}
	}
	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: -710px;
		height: 645px;
		width: 80vw;
		background-image: url(../images/about-page-thumb.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@media #{$lg} {
			width: 94vw;
		}
		@media #{$md} {
			width: 100%;
		}
		@media #{$xs} {
			display: none;
		}
		@media #{$sm} {
			width: 100%;
			height: 400px;
			display: block;
		}
	}
}



.appie-how-it-work-area{}

.appie-how-it-work-content{
	& > .title{
		font-size: 44px;
	}
	& p{
		font-size: 18px;
		padding-top: 9px;
	}
	& .how-it-work-box{
		position: relative;
		padding-left: 70px;
		margin-top: 45px;
		& span{
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 50px;
			width: 50px;
			text-align: center;
			line-height: 50px;
			border-radius: 50%;
			background: #eef4ff;
			color: $theme-color;
			font-size: 22px;
			font-weight: 500;
		}
		& .title{
			font-size: 18px;
			font-weight: 500;
		}
		&:hover{
			& span{
				background: $theme-color;
				box-shadow: 0px 14px 20px 0px rgba(0, 43, 130, 0.3);
				color: $white;
			}
		}
	}
}





.appie-about-8-area{
	background-image: url(../images/background-bg.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.appie-about-8-box{
	padding: 50px 50px 145px;
	background: $white;
	border-radius: 6px;
	box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
	position: relative;
	overflow: hidden;
	z-index: 10;
	@media #{$lg} {
		padding: 50px 25px 145px;
	}
	@media #{$md} {
		margin-bottom: 30px;
	}
	@media #{$xs} {
		margin-bottom: 30px;
		padding: 50px 20px 145px;
	}
	& .thumb{
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: -1;
	}
	& .title{
		font-size: 30px;
		line-height: 40px;
		font-weight: 500;
		margin-bottom: 14px;
		@media #{$xs} {
			font-size: 24px;
			line-height: 34px;
		}
		@media #{$sm} {
			font-size: 30px;
			line-height: 40px;
		}
	}
	& a{
		border: 2px solid $theme-color-7;
		color: $theme-color-7;
		background: $white;
		margin-top: 30px;
		&:hover{
			color: $white;
			background: $theme-color-7;
			border-color: $theme-color-7;
		}
	}
}


