


/*===========================
    6.APPIE TRAFFICE css 
===========================*/





.appie-testimonial-area{
	background-image: url(../images/testimonial-bg.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.appie-testimonial-slider-rtl,
.appie-testimonial-slider{
	& .appie-testimonial-item{
		& .author-info{
			margin-bottom: 15px;
			& img{
				display: inline-block;
			}
			& .title{
				font-weight: 500;
				font-size: 16px;
				color: $white;
				padding-top: 10px;
			}
			& span{
				font-size: 14px;
				color: $white;
				opacity: 0.5;
			}
		}
		& .text{
			& p{
				font-size: 20px;
				line-height: 30px;
				color: $white;
			}
			& ul{
				margin: 10px 0 0;
				padding: 0;
				list-style-type: none;
				& li{
					display: inline-block;
					margin: 0 2px;
					& i{
						color: #ffb848;
					}
				}
			}
		}
	}
	& .slick-arrow{
		position: absolute;
		top: 62%;
		transform: translateY(-50%);
		font-size: 30px;
		color: $white;
		z-index: 11;
		cursor: pointer;
		opacity: 0.5;
		&.prev{
			left: -80px;
		}
		&.next{
			right: -80px;
		}
	}
	& .slick-dots{
		position: absolute;
		left: 50%;
		bottom: -65px;
		transform: translateX(-50%);
		& li{
			display: inline-block;
			& button{
				border: 0;
				background: $white;
				height: 8px;
				width: 8px;
				border-radius: 50%;
				font-size: 0;
				opacity: 0.5;
				margin: 0 4px;
			}
			&.slick-active{
				& button{
					opacity: 1;
				}
			}
		}
	}
}






.appie-testimonial-2-box{
	background-image: url(../images/testimonial-bg-2.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 120px 140px;
	@media #{$lg} {
		padding: 50px;
	}
	@media #{$md} {
		padding: 50px 30px;
	}
	@media #{$xs} {
		padding: 50px 30px;
	}
}



.appie-testimonial-slider-2{
	& .slick-arrow{
		position: absolute;
		right: 0;
		bottom: 0px;
		height: 50px;
		width: 50px;
		text-align: center;
		line-height: 50px;
		border: 2px solid rgba(255,255,255,.2);
		color: $white;
		border-radius: 50%;
		z-index: 9;
		cursor: pointer;
		&:hover{
			background: $theme-color-2;
			color: $white;
			border-color: $theme-color-2;
		}
		&.prev{
			right: 60px;
		}
		&.next{
			right: 0;
		}
	}
}


.appie-testimonial-slider-2-item{
	& .item{
		display: flex;
		padding-right: 30px;
		padding-left: 30px;

		@media #{$md} {
			display: block;
		}
		@media #{$xs} {
			display: block;
			padding-left: 0;
			padding-right: 0;
		}
	}
	& .thumb{
		text-align: center;
		min-width: 140px;
		margin-right: 60px;

		@media #{$md} {
			margin-bottom: 30px;
			margin-right: 0;
		}
		@media #{$xs} {
			margin-bottom: 30px;
			margin-right: 0;
		}
		& img{
			display: inline-block;
			border-radius: 50%;
			box-shadow: 0px 20px 30px 0px rgba(0, 16, 49, 0.1);
			margin-bottom: 13px;
		}
		& ul{
			margin: 0;
			padding: 0;
			list-style-type: none;
			& li{
				display: inline-block;
				& i{
					font-size: 14px;
					color: #ffb848;
				}
			}
		}
		& span{
			font-size: 13px;
			font-weight: 500;
			color: $white;
			opacity: 0.7;
		}
	}
	& .content{
		@media #{$md} {
			text-align: center;
		}
		@media #{$xs} {
			text-align: center;
		}
		& p{
			color: $white;
			font-size: 20px;
			line-height: 30px;
			@media #{$xs} {
				font-size: 16px;
			}
		}
		& .author-info{
			margin-top: 27px;
			& .title{
				color: $white;
				font-size: 20px;
				font-weight: 700;
			}
			& span{
				font-size: 14px;
				color: $white;
				opacity: .7;
			}
		}
	}
}




.appie-testimonial-about-area{
	background-image: url(../images/map-bg.png);
	background-size: auto;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	& .appie-section-title{
		margin-bottom: 30px;
		& .appie-title{
			padding: 0 30px;
		}
	}
}


.testimonial-about-slider-active{
	& .testimonial-box{
		padding: 60px 100px 80px;
		box-shadow: 0px 40px 60px 0px rgba(14, 17, 51, 0.1);
		text-align: center;
		background: $white;
		margin-left: 100px;
		margin-right: 100px;
		margin-bottom: 80px;
		position: relative;
		@media #{$lg} {
			padding: 60px 50px 80px;
		}
		@media #{$md} {
			margin-left: 50px;
			margin-right: 50px;
			padding: 30px 30px 50px;
		}
		@media #{$xs} {
			margin-left: 0px;
			margin-right: 0px;
			padding: 30px 30px 50px;
		}
		&::before{
			position: absolute;
			content: '';
			left: 50%;
			top: 100%;
			transform: translateX(-50%);
 			width: 0;
      		height: 0;
        	border-left: 20px solid transparent;
        	border-right: 20px solid transparent;
        	border-top: 20px solid #fff;
		}
		& .icon{
			& i{
				font-size: 32px;
				color: #4985ff;
			}
		}
		& .ratings-icon{
			padding-top: 25px;
			padding-bottom: 20px;
			& ul{
				& li{
					display: inline-block;
					& i{
						color: #faa116;
						font-size: 14px;
					}
				}
			}
		}
		& p{
			font-size: 20px;
			color: $heading-color;
			line-height: 30px;
			@media #{$xs} {
				font-size: 16px;
				line-height: 26px;
			}
		}
	}
	& .slick-arrow{
		position: absolute;
		top: 43%;
		transform: translateY(-50%);
		z-index: 9;
		cursor: pointer;
		height: 40px;
		width: 40px;
		text-align: center;
		line-height: 36px;
		border: 2px solid #db0f30;
		color: #db0f30;
		border-radius: 50%;
		transition: all linear 0.3s;
		background: $white;
		&:hover{
			background: #db0f30;
			color: $white;
		}
		&.prev{
			left: 80px;
			@media #{$md} {
				left: 0;
			}
			@media #{$xs} {
				left: -10px;
			}
		}
		&.next{
			right: 80px;
			@media #{$md} {
				right: 0;
			}
			@media #{$xs} {
				right: -10px;
			}
		}
	}
}




.testimonial-box-about-slider-small-active{
	margin-top: -40px;
	& .item{
		padding-top: 20px;
		padding-bottom: 50px;
		& .thumb{
			max-width: 70px;
			text-align: center;
			& img{
				display: inline-block;
				width: 40px;
			}
		}
		& .content{
			display: none;
			text-align: center;
			
			& .title{
				font-size: 16px;
				font-weight: 700;
				white-space: nowrap;
			}
			& span{
				font-size: 13px;
				font-weight: 500;
				color: #888890;
				white-space: nowrap;
			}
		}
		&.slick-center{
			margin-top: -20px;
			@media #{$lg} {
				margin-top: -10px;
			}

			& .thumb{
				max-width: 70px;
				& img{
					width: 70px;
				}
			}
			& .content{
				display: inline-block;
				margin: 10px 0 0 -24px;
			}
		}
	}
}


