


/*===========================
    7.APPIE TEAM css 
===========================*/


.appie-team-item{
	& .thumb{
		position: relative;
		z-index: 10;
		overflow: hidden;
		& img{
			@include transition(0.3s);
			width: 100%;			
		}
		&::before{
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: #000;
			opacity: 0.5;
			z-index: 9;
			opacity: 0;
			@include transition(0.3s);
		}
		& ul{
			position: absolute;
			left: 50%;
			bottom: 30px;
			transform: translateX(-50%);
			margin: 0;
			padding: 0;
			list-style-type: none;
			z-index: 11;
			opacity: 0;
			visibility: hidden;
			@include transition(0.3s);
			& li{
				display: inline-block;
				margin: 0 3px;
				& a{
					display: inline-block;
					height: 36px;
					width: 36px;
					border: 2px solid rgba(255,255,255,0.5);
					text-align: center;
					line-height: 36px;
					border-radius: 50px;
					color: $white;
					&:hover{
						background: $white;
						color: $theme-color;
						border-color: $white;
					}
				}
			}
		}
	}
	& .content{
		padding-top: 27px;
		& .title{
			font-size: 18px;
			font-weight: 700;
			padding-bottom: 8px;
		}
		& span{
			font-size: 14px;
			color: #81818d;
		}
	}
	&:hover{
		& .thumb{
			&::before{
				opacity: 0.5;
			}
			& ul{
				opacity: 1;
				visibility: visible;
			}
			& img{
				transform: scale(1.1);
			}
		}
	}
	&.appie-team-item-about{
		& .thumb{
			border-radius: 6px;
			overflow: hidden;
			&::before{
				background-color: rgb(43, 112, 250);
			}
			& ul{
				left: 30px;
				transform: translateX(0);
				& li{
					& a{
						border-radius: 6px;
					}
				}
			}
		}
		&:hover{
			& .thumb{
				&::before{
					opacity: 0.9;
				}
			}
		}
		&.appie-team-item-8{
			& .thumb{
				&::before{
					background: $theme-color-7;
				}
				& ul{
					& li{
						& a{
							&:hover{
								color: $theme-color-7;
							}
						}
					}
				}
			}
		}
	}
}


.team-btn{
	& a{
		background: transparent;
		color: $heading-color;
		border: 2px solid #e7eaef;
		& i{
			padding-left: 6px;
		}
		&:hover{
			background: $theme-color;
			color: $white;
		}
		
	}
}

.appie-team-about-area{
	.team-btn{
		& a{
			border-color: $theme-color;
			color: $theme-color;
			&:hover{
				color: $white;
			}
		}
	}
	&.appie-team-8-area{
		& .team-btn{
			& a{
				border-color: $theme-color-7;
				color: $theme-color-7;
				&:hover{
					background: $theme-color-7;
					color: $white;
				}
			}
		}
	}
}

